import _crc from "./crc1";
import _crc2 from "./crc8";
import _crc8_1wire from "./crc8_1wire";
import _crc3 from "./crc16";
import _crc16_ccitt from "./crc16_ccitt";
import _crc16_modbus from "./crc16_modbus";
import _crc16_xmodem from "./crc16_xmodem";
import _crc16_kermit from "./crc16_kermit";
import _crc4 from "./crc24";
import _crc5 from "./crc32";
import _crcjam from "./crcjam";
var exports = {};
exports = {
  crc1: _crc,
  crc8: _crc2,
  crc81wire: _crc8_1wire,
  crc16: _crc3,
  crc16ccitt: _crc16_ccitt,
  crc16modbus: _crc16_modbus,
  crc16xmodem: _crc16_xmodem,
  crc16kermit: _crc16_kermit,
  crc24: _crc4,
  crc32: _crc5,
  crcjam: _crcjam
};
export default exports;
export const crc1 = exports.crc1;