import _buffer2 from "buffer";
import _create_buffer3 from "./create_buffer";
import _define_crc3 from "./define_crc";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _buffer = _buffer2;
var _create_buffer = _create_buffer3;

var _create_buffer2 = _interopRequireDefault(_create_buffer);

var _define_crc = _define_crc3;

var _define_crc2 = _interopRequireDefault(_define_crc);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var crc1 = (0, _define_crc2.default)("crc1", function (buf, previous) {
  if (!_buffer.Buffer.isBuffer(buf)) buf = (0, _create_buffer2.default)(buf);
  var crc = ~~previous;
  var accum = 0;

  for (var index = 0; index < buf.length; index++) {
    var byte = buf[index];
    accum += byte;
  }

  crc += accum % 256;
  return crc % 256;
});
exports.default = crc1;
export default exports;